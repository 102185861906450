// Copyright 2021-2025 - Hewlett Packard Enterprise Company
import React, { lazy, Suspense } from 'react';
import { Grommet, Box } from 'grommet';
import { hpe } from 'grommet-theme-hpe';
import AuthProvider, { AuthConsumer } from './providers/AuthProvider';
import HelpProvider from './providers/HelpProvider';

import LaunchDarklyUser from './components/Flags/LaunchDarklyUser';
import StorageEventHandler from './components/StorageEventHandler/StorageEventHandler';
import LoadingSpinnerWithText from './components/LoadingSpinner/LoadingSpinnerWithText';
import UnauthenticatedApp from './UnauthenticatedApp';
import { REDIRECT_URI_KEY } from './constants/authConst';
import Login from './auth/Login';
import LoadingSpinner from './components/LoadingSpinner/LoadingSpinner';

const AuthenticatedApp = lazy(() => import('./AuthenticatedApp'));

const spinner = (
  <Box align="center" pad="small">
    <LoadingSpinner size="medium" />
  </Box>
);

export default function App() {
  return (
    <HelpProvider>
      {/*
       * 100vh is the standard, however need to account for the Global Header-Footer
       * Service. 88vh was emprically derived based on dimensions of the header-footer service.
       */}
      <Grommet theme={hpe} style={{ minHeight: '88vh' }}>
        <StorageEventHandler />
        <AuthProvider>
          <LaunchDarklyUser />
          <UnauthenticatedApp>
            <Suspense
              fallback={
                <Box
                  height="xsmall"
                  margin={{ top: 'small' }}
                  width="100%"
                  align="center"
                  justify="center"
                  alignSelf="center"
                >
                  <LoadingSpinnerWithText
                    label="Loading..."
                    orientation="horizontal"
                  />
                </Box>
              }
            >
              <AuthConsumer>
                {({ isAuthenticated, token }) => {
                  if (isAuthenticated()) {
                    return token ? <AuthenticatedApp /> : spinner;
                  }
                  sessionStorage.setItem(
                    REDIRECT_URI_KEY,
                    window.location.pathname,
                  );
                  return <Login />;
                }}
              </AuthConsumer>
            </Suspense>
          </UnauthenticatedApp>
        </AuthProvider>
      </Grommet>
    </HelpProvider>
  );
}
