// Copyright 2021-2025 - Hewlett Packard Enterprise Company

import { getDefaultContext } from '../utils/launchDarklyHelper';

// if the LD client ID is not present, do not provide an LD config
const LaunchDarklyConfig = window.indigo.REACT_APP_LAUNCHDARKLY_CLIENT_ID
  ? {
      clientSideID: window.indigo.REACT_APP_LAUNCHDARKLY_CLIENT_ID,
      context: getDefaultContext(),
      options: {
        bootstrap: 'localStorage',
        // useReport is a best practice to avoid user data in web server logs
        // https://docs.launchdarkly.com/guides/best-practices/user-data
        useReport: true,
        // by default analytics events are sent for ALL flags on initialization
        // eslint-disable-next-line max-len
        // https://launchdarkly.github.io/js-client-sdk/interfaces/_launchdarkly_js_client_sdk_.ldoptions.html#sendeventsonlyforvariation
        sendEventsOnlyForVariation: true,
      },
      reactOptions: {
        // camel casing of keys is default for backward compatibility, but not recommended
        useCamelCaseFlagKeys: false,
      },
    }
  : null;

export default LaunchDarklyConfig;
