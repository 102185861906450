// Copyright 2022-2025 - Hewlett Packard Enterprise Company
import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import './i18n';
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import './grommet-leaflet.css';

import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import LaunchDarklyConfig from './flags/LaunchDarklyConfig';

import App from './App';

(async () => {
  // don't bootstrap LD if we don't have an LD config
  const useLD = !!LaunchDarklyConfig;
  let LDProvider;

  if (useLD) {
    LDProvider = await asyncWithLDProvider(LaunchDarklyConfig);
  }

  ReactDOM.render(
    <React.StrictMode>
      {useLD ? (
        <LDProvider>
          <App />
        </LDProvider>
      ) : (
        <App />
      )}
    </React.StrictMode>,
    document.getElementById('root'),
  );
})();
