// Copyright 2024-2025 - Hewlett Packard Enterprise Company
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import AuthCallback from './auth/AuthCallback';
import Login from './auth/Login';
import Logout from './auth/Logout';

export default function UnauthenticatedApp({ children }) {
  return (
    <BrowserRouter basename="/">
      <Switch>
        <Route path={['/auth', '/login', '/logout']}>
          <Switch>
            <Route exact path="/auth" component={AuthCallback} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/logout" component={Logout} />
          </Switch>
        </Route>
        <Route>{children}</Route>
      </Switch>
    </BrowserRouter>
  );
}
