// Copyright 2024 - Hewlett Packard Enterprise Company
const ANONYMOUS = 'anonymous';

export function getDefaultEmail() {
  return window.indigo.REACT_APP_LAUNCHDARKLY_USER_EMAIL || ANONYMOUS;
}

export function getDefaultContext() {
  // Use a shared key for users before they are logged in
  // Once we have a token, we will update the user in the LD client
  return {
    kind: 'user',
    key: ANONYMOUS,
    email: getDefaultEmail(),
    environment: window.indigo.RUGBY_ENV,
    service: 'indigo',
    anonymous: true,
  };
}

export async function getUserKey(email, cid) {
  const keyText = `${email}:${cid}`;
  const hashed = await crypto.subtle.digest(
    'SHA-256',
    new TextEncoder().encode(keyText),
  );
  const hashArray = Array.from(new Uint8Array(hashed));
  return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
}
