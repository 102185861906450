// Copyright 2021-2024 - Hewlett Packard Enterprise Company
import React, { createContext, useState } from 'react';
import { CONTENT_IDS } from '../constants';

export const HelpContext = createContext();

export default function HelpProvider({ children }) {
  const [contentId, setContentId] = useState(CONTENT_IDS.ANNOUNCEMENTS);
  const [isHelpOpen, setIsHelpOpen] = useState(false);
  const [isNotificationCenterOpen, setIsNotificationCenterOpen] =
    useState(false);

  return (
    <HelpContext.Provider
      value={{
        contentIds: CONTENT_IDS,
        contentId,
        setContentId,
        isHelpOpen,
        setIsHelpOpen: isOpen => {
          if (isOpen) {
            setIsNotificationCenterOpen(!isOpen);
          }
          setIsHelpOpen(isOpen);
        },
        isNotificationCenterOpen,
        setIsNotificationCenterOpen: isOpen => {
          setIsNotificationCenterOpen(isOpen);
          if (isOpen) {
            setIsHelpOpen(!isOpen);
          }
        },
      }}
    >
      {children}
    </HelpContext.Provider>
  );
}
