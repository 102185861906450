// Copyright 2021-2024 - Hewlett Packard Enterprise Company

import { useContext, useEffect } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { AuthContext } from '../../providers/AuthProvider';
import {
  getDefaultContext,
  getUserKey,
  getDefaultEmail,
} from '../../utils/launchDarklyHelper';

export default function LaunchDarklyUser() {
  // When the auth token changes (e.g. because the user logged in)
  // Read data out of the new token and update the Launch Darkly user
  const ldClient = useLDClient();
  const { getCid, getUserEmail, token } = useContext(AuthContext);
  useEffect(() => {
    let isMounted = true;
    async function updateLDUser() {
      if (!ldClient) {
        // can't do anything until the client is instantiated
        return;
      }

      const email =
        window.indigo.REACT_APP_LAUNCHDARKLY_USER_EMAIL ||
        (await getUserEmail());
      const cid = await getCid();

      const ldUser = getDefaultContext();
      if (cid && email) {
        // hash email and customer ID from jwt into user key for Launch Darkly
        ldUser.key = await getUserKey(email, cid);

        // set the customer ID for customer based flag targeting
        ldUser.cid = cid;

        if (email.endsWith('@hpe.com') || email === getDefaultEmail()) {
          ldUser.email = email;
          ldUser.anonymous = false;
        } else {
          delete ldUser.email;
          ldUser.anonymous = true;
        }
      }

      if (isMounted) {
        const prevUser = ldClient.getContext();
        if (JSON.stringify(prevUser) !== JSON.stringify(ldUser)) {
          // eslint-disable-next-line no-console
          console.log('Updating LD user from %o to %o', prevUser, ldUser);
          await ldClient.identify(ldUser);
        }
      }
    }
    updateLDUser();

    return () => {
      isMounted = false;
    };
    // token is a required depency to ensure that this component is re-rendered whenever the token
    // changes. It is not directly used by this effect.
  }, [ldClient, getCid, getUserEmail, token]);

  return null;
}
