// Copyright 2022-2025 - Hewlett Packard Enterprise Company
import { v4 as uuidv4 } from 'uuid';
import { compareBundles } from './apiHelpers';
import {
  SETTING_CATEGORY_FIRMWARE,
  SETTING_CATEGORY_SERVER_FIRMWARE,
  SETTING_CATEGORY_OS_IMAGE,
  SETTING_CATEGORY_SERVER_OS_IMAGE,
  SETTING_CATEGORY_INTERNAL_STORAGE,
  SETTING_CATEGORY_SERVER_INTERNAL_STORAGE,
  SETTING_CATEGORY_DISPLAY_NAME_EXTERNAL_STORAGE,
  SETTING_CATEGORY_DISPLAY_NAME_SERVER_EXTERNAL_STORAGE,
  SETTING_CATEGORY_DISPLAY_NAME_BIOS,
  SETTING_CATEGORY_SERVER_BIOS,
  NOT_SET,
  MIN_DRIVE_FOR_RAID_TYPES,
  USE_ENTIRE_DISK,
  SPECIFY_MULTI_VOLUME_SIZE,
} from '../constants';
import { getServerSettings } from '../actions/Api';
import { formatScheduleStartTimestamp } from './timeHelpers';
import {
  convertSizeFromGBToBytes,
  convertSizeFromBytestoGiBWithTwoDecimal,
} from './inputHelpers';
import i18n from '../i18n';
import { ILO_NETWORK } from '../constants/iloSettingsConfigurationConstants';

const BUNDLE_NOT_SUPPORTED =
  'This base SPP bundle is no longer supported. ' +
  'Select a more recent SPP bundle.';

const EDGELINE_BUNDLE_NOT_SUPPORTED =
  'This base SPE bundle is no longer supported. ' +
  'Select a more recent SPE bundle.';

const OV_VERSION_NOT_SUPPORTED =
  'This version is no longer supported. ' + 'Select a more recent version.';

export async function validateUniqueName({ id, name }) {
  let response = {};
  response = await getServerSettings(
    0,
    1,
    null, // sort
    [`name eq '${name.replaceAll('\\', '\\\\').replaceAll("'", "\\'")}'`],
  ).catch(() => {
    // If the REST request fails, go ahead and
    // send the POST request.  The POST will fail
    // if the name is a duplicate.
    response.data = { count: 0 };
  });

  const result = { isValid: true };
  if (
    response?.data?.count &&
    response?.data?.items &&
    response.data.items[0].id !== id
  ) {
    result.isValid = false;
    result.errorMessage =
      'Another server setting has the same name. Enter a unique name.';
  }

  return result;
}

export function validateBaselineSelection(formValues) {
  const result = { isValid: true };
  if (
    !formValues.gen10Baseline &&
    !formValues.gen11Baseline &&
    !formValues.gen12Baseline &&
    !formValues.edgelineBaseline
  ) {
    result.isValid = false;
    result.errorMessage =
      'Select a base bundle to proceed with creating the server setting.';
    result.validator = validateBaselineSelection;
  }
  return result;
}

export function validateVmApplianceFirmwareSelection(formValues) {
  const result = { isValid: true };
  if (!formValues.vmApplianceVersion) {
    result.isValid = false;
    result.errorMessage = i18n.t(
      'common:server_setting_wizard.step_set_appliance_firmware.version_validation',
    );
    result.validator = validateVmApplianceFirmwareSelection;
  } else if (!formValues.vmApplianceVersion.isActive) {
    result.isValid = false;
    result.errorMessage = OV_VERSION_NOT_SUPPORTED;
    result.validator = validateVmApplianceFirmwareSelection;
  }
  return result;
}

export function validateSynergyApplianceFirmwareSelection(formValues) {
  const result = { isValid: true };
  if (!formValues.synergyApplianceVersion) {
    result.isValid = false;
    result.errorMessage = i18n.t(
      'common:server_setting_wizard.step_set_appliance_firmware.version_validation',
    );
    result.validator = validateSynergyApplianceFirmwareSelection;
  } else if (!formValues.synergyApplianceVersion.isActive) {
    result.isValid = false;
    result.errorMessage = OV_VERSION_NOT_SUPPORTED;
    result.validator = validateSynergyApplianceFirmwareSelection;
  }
  return result;
}

export function getBundleIdFromForm(baseline, patch) {
  if (patch?.id?.trim()) return patch.id;
  if (baseline?.id) return baseline.id;
  return null;
}

export function validateGen10Baseline(value, { gen10Patch }) {
  if (
    value?.isActive === false &&
    (!gen10Patch?.selfUri || gen10Patch?.isActive === false)
  ) {
    return {
      message: BUNDLE_NOT_SUPPORTED,
      status: 'error',
    };
  }
  return {
    message: '',
    status: 'info',
  };
}

export function validateApplianceFirmwareVersion(
  value,
  { vmApplianceVersion, synergyApplianceVersion },
) {
  if (
    vmApplianceVersion?.isActive === false ||
    synergyApplianceVersion?.isActive === false
  ) {
    return {
      message: OV_VERSION_NOT_SUPPORTED,
      status: 'error',
    };
  }
  return {
    message: '',
    status: 'info',
  };
}

export function validateGen11Baseline(value, { gen11Patch }) {
  if (
    value?.isActive === false &&
    (!gen11Patch?.selfUri || gen11Patch?.isActive === false)
  ) {
    return {
      message: BUNDLE_NOT_SUPPORTED,
      status: 'error',
    };
  }
  return {
    message: '',
    status: 'info',
  };
}

export function validateGen12Baseline(value, { gen12Patch }) {
  if (
    value?.isActive === false &&
    (!gen12Patch?.selfUri || gen12Patch?.isActive === false)
  ) {
    return {
      message: BUNDLE_NOT_SUPPORTED,
      status: 'error',
    };
  }
  return {
    message: '',
    status: 'info',
  };
}

export function validateEdgelineBaseline(value, { edgelinePatch }) {
  if (
    value?.isActive === false &&
    (!edgelinePatch?.selfUri || edgelinePatch?.isActive === false)
  ) {
    return {
      message: EDGELINE_BUNDLE_NOT_SUPPORTED,
      status: 'error',
    };
  }
  return {
    message: '',
    status: 'info',
  };
}

export function validatePatch(value) {
  if (value?.isActive === false) {
    return {
      message:
        'This hotfix bundle is no longer supported. ' +
        'Select a more recent SPP or hotfix bundle.',
      status: 'error',
    };
  }
  return {
    message: '',
    status: 'info',
  };
}

export function validateEdgelinePatch(value) {
  if (value?.isActive === false) {
    return {
      message:
        'This patch bundle is no longer supported. ' +
        'Select a more recent SPE or patch bundle.',
      status: 'error',
    };
  }
  return {
    message: '',
    status: 'info',
  };
}

export function hasDataFetchError(dataFetchError, category, baselines) {
  const baselinesMissing =
    !category || category === 'FIRMWARE'
      ? !baselines || (baselines && baselines.length === 0)
      : false;
  return dataFetchError || baselinesMissing;
}

// Returns true if a firmware setting specifies an inactive bundle
export function hasInactiveBundle(firmwareSetting, baselines) {
  let result = false;

  const settings = firmwareSetting?.settings;
  const generations = settings
    ? Object.keys(settings).filter(generation => settings[generation])
    : undefined;

  if (generations && baselines) {
    result = generations.some(generation => {
      const settingBaseline = settings[generation];
      const firmwareBundle = baselines.find(
        bundle => bundle?.id === settingBaseline?.id,
      );

      return !firmwareBundle || !firmwareBundle.isActive;
    });
  }

  return result;
}

export const ovApplianceSettings = (t, enhancedOvSecuritySettingsFlag) => ({
  security: {
    label: t('ov_appliance_settings.security_label'),
    keys: [
      ...(enhancedOvSecuritySettingsFlag
        ? [
            {
              label: t('ov_appliance_settings.two_factor_authentication_label'),
              key: 'twoFactorAuthenticationEnabled',
            },
            {
              label: t('ov_appliance_settings.local_login_label'),
              key: 'allowLocalLogin',
            },
          ]
        : []),
      {
        label: t('ov_appliance_settings.svc_console_access_label'),
        key: 'enableServiceAccess',
      },
      {
        label: t('ov_appliance_settings.complex_pswd_label'),
        key: 'enforceComplexPasswordEnabled',
      },
      {
        label: t('ov_appliance_settings.ssh_access_label'),
        key: 'allowSshAccess',
      },
      ...(enhancedOvSecuritySettingsFlag
        ? [
            {
              label: t(
                'ov_appliance_settings.directories_directory_groups_default_group_label',
              ),
              key: 'directories',
            },
          ]
        : []),
      {
        label: t('ov_appliance_settings.cert_validation_label'),
        key: 'certValidationConfig',
      },
      {
        label: t('ov_appliance_settings.audit_log_fwd_label'),
        key: 'auditLogForwarding',
      },
      {
        label: t('ov_appliance_settings.product_impr_enroll_label'),
        key: 'productImprovement',
      },
    ],
  },
  notifications: {
    label: t('ov_appliance_settings.notifications_label'),
    keys: [
      {
        label: t('ov_appliance_settings.email_destination_label'),
        key: 'emailDestination',
      },
      {
        label: t('ov_appliance_settings.alert_email_filter_label'),
        key: 'alertEmailFilters',
      },
    ],
  },
  proxy: {
    label: t('ov_appliance_settings.proxy_label'),
    keys: [
      {
        label: t('ov_appliance_settings.proxy_settings_label'),
        key: 'proxy',
      },
    ],
  },
  remoteSupport: {
    label: t('ov_appliance_settings.remote_support_label'),
    keys: [
      {
        label: t('ov_appliance_settings.general_settings_label'),
        key: 'generalSettingsContactsPartners',
      },
      {
        label: t('ov_appliance_settings.default_collection_schedule_label'),
        key: 'defaultCollectionSchedule',
      },
    ],
  },
  snmp: {
    label: t('ov_appliance_settings.snmp_label'),
    keys: [
      {
        label: t('ov_appliance_settings.trap_dstn_label'),
        key: 'snmp',
      },
    ],
  },
  timeAndLocale: {
    label: t('ov_appliance_settings.time_locale_label'),
    keys: [
      {
        label: t('ov_appliance_settings.time_settings_label'),
        key: 'time',
      },
      {
        label: t('ov_appliance_settings.lang_locale_label'),
        key: 'languageLocale',
      },
    ],
  },
  updates: {
    label: t('ov_appliance_settings.updates_label'),
    keys: [
      {
        label: t('ov_appliance_settings.settings_and_schedule_label'),
        key: 'updates',
      },
    ],
  },
  globalSettings: {
    label: t('ov_appliance_settings.global_label'),
    keys: [
      {
        label: t('ov_appliance_settings.server_hardware_label'),
        key: 'serverManagementProcessorNtpSource',
      },
      {
        label: t('ov_appliance_settings.server_profile_label'),
        key: 'profileBIOSConsistency',
      },
      {
        label: t('ov_appliance_settings.storage_label'),
        key: 'storageSettings',
      },
      {
        label: t('ov_appliance_settings.user_interface_label'),
        key: 'uiSettings',
      },
    ],
  },
});

export const ovSynergyApplianceSettings = t => ({
  globalSettings: {
    label: t('ov_synergy_appliance_settings.global_label'),
    keys: [
      {
        label: t('ov_synergy_appliance_settings.synergy_interconnect_label'),
        key: 'interconnectManagerNtpSource',
      },
      {
        label: t(
          'ov_synergy_appliance_settings.synergy_logical_interconnect_label',
        ),
        key: 'reservedVlanRange',
      },
    ],
  },
});

export const processApplianceSettingsData = (
  response,
  formValues,
  operation,
  applianceSettings,
) => {
  const processedData = {};
  const singleSettingsInType = ['updates', 'snmp', 'proxy'];
  Object.entries(applianceSettings).map(([id, setting]) => {
    const { label, keys } = setting;
    const settingsSelected = formValues[id];
    const settingsValue = response?.items?.[0]?.settings?.filter(
      data => data.settingsType === id,
    )?.[0]?.settingsValue;
    // To show not selected setting in side drawer
    const settingsNotSelected = [];
    const settingsCheck = {};
    let selectedSettingsValue = {};

    keys.forEach(k => {
      if (settingsSelected.includes(k.key)) {
        if (k.key === 'emailDestination') {
          settingsCheck[k.key] = true;
          if (settingsValue) {
            selectedSettingsValue.senderEmailAddress =
              settingsValue.senderEmailAddress;
            selectedSettingsValue.smtpServer = settingsValue.smtpServer;
            selectedSettingsValue.smtpPort = settingsValue.smtpPort;
            selectedSettingsValue.smtpProtocol = settingsValue.smtpProtocol;
          }
        } else if (k.key === 'alertEmailFilters') {
          settingsCheck[k.key] = true;
          settingsCheck.alertEmailDisabled = true;
          if (settingsValue) {
            selectedSettingsValue.alertEmailFilters =
              settingsValue.alertEmailFilters;
            selectedSettingsValue.alertEmailDisabled =
              settingsValue.alertEmailDisabled;
          }
        } else if (k.key === 'certValidationConfig') {
          settingsCheck[k.key] = true;
          settingsCheck.autoDownloadCRL = true;
          settingsCheck.checkCertificateRevocation = true;
          settingsCheck.validateCertificate = true;
          if (settingsValue) {
            selectedSettingsValue.certValidationConfig =
              settingsValue.certValidationConfig;
          }
        } else if (k.key === 'time') {
          settingsCheck[k.key] = true;
          if (settingsValue) {
            selectedSettingsValue.ntpServers = settingsValue.ntpServers;
            selectedSettingsValue.timeSource = settingsValue.timeSource;
          }
        } else if (k.key === 'languageLocale') {
          settingsCheck[k.key] = true;
          if (settingsValue) {
            selectedSettingsValue.locale = settingsValue.locale;
            selectedSettingsValue.localeDisplayName =
              settingsValue.localeDisplayName;
          }
        } else if (k.key === 'generalSettingsContactsPartners') {
          settingsCheck[k.key] = true;
          if (settingsValue) {
            selectedSettingsValue.configuration = settingsValue.configuration;
            selectedSettingsValue.channelPartner = settingsValue.channelPartner;
            selectedSettingsValue.contacts = settingsValue.contacts;
            selectedSettingsValue.sites = settingsValue.sites;
          }
        } else if (k.key === 'defaultCollectionSchedule') {
          settingsCheck[k.key] = true;
          if (settingsValue) {
            selectedSettingsValue.schedule = settingsValue.schedule;
            selectedSettingsValue.schedulesTimeZone =
              settingsValue.schedulesTimeZone;
          }
        } else if (k.key === 'directories') {
          settingsCheck[k.key] = true;
          if (settingsValue) {
            selectedSettingsValue.directories = settingsValue.directories;
            selectedSettingsValue.userGroups = settingsValue.userGroups;
            selectedSettingsValue.defaultLoginDomain =
              settingsValue.defaultLoginDomain;
          }
        } else if (k.key === 'allowLocalLogin') {
          settingsCheck[k.key] = true;
          if (settingsValue) {
            selectedSettingsValue.allowLocalLogin =
              settingsValue.allowLocalLogin;
            selectedSettingsValue.emergencyLocalLoginEnabled =
              settingsValue.emergencyLocalLoginEnabled;
            selectedSettingsValue.emergencyLocalLoginType =
              settingsValue?.emergencyLocalLoginType;
          }
        } else if (k.key === 'twoFactorAuthenticationEnabled') {
          settingsCheck[k.key] = true;
          if (settingsValue) {
            selectedSettingsValue.twoFactorAuthenticationEnabled =
              settingsValue?.twoFactorAuthenticationEnabled;
            selectedSettingsValue.strictTwoFactorAuthentication =
              settingsValue?.strictTwoFactorAuthentication;
            selectedSettingsValue.loginCertificates =
              settingsValue?.loginCertificates;
          }
        } else {
          settingsCheck[k.key] = true;
          // filter selected settings value from iris response
          // Updates, SNMP handled differntly to comply with given schema
          if (settingsValue) {
            if (singleSettingsInType.includes(k.key)) {
              selectedSettingsValue = settingsValue;
            } else {
              selectedSettingsValue[k.key] = settingsValue[k.key];
            }
          }
        }
      } else {
        settingsCheck[k.key] = false;
        settingsNotSelected.push(k);
        if (operation === 'Update') {
          if (k.key === 'emailDestination') {
            selectedSettingsValue.senderEmailAddress = null;
            selectedSettingsValue.smtpServer = null;
            selectedSettingsValue.smtpPort = null;
            selectedSettingsValue.smtpProtocol = null;
          } else if (k.key === 'alertEmailFilters') {
            selectedSettingsValue.alertEmailFilters = null;
            selectedSettingsValue.alertEmailDisabled = null;
          } else if (k.key === 'time') {
            selectedSettingsValue.ntpServers = null;
            selectedSettingsValue.timeSource = null;
          } else if (k.key === 'languageLocale') {
            selectedSettingsValue.locale = null;
            selectedSettingsValue.localeDisplayName = null;
          } else if (k.key === 'generalSettingsContactsPartners') {
            selectedSettingsValue.configuration = null;
            selectedSettingsValue.channelPartner = null;
            selectedSettingsValue.contacts = null;
            selectedSettingsValue.sites = null;
          } else if (k.key === 'defaultCollectionSchedule') {
            selectedSettingsValue.schedule = null;
            selectedSettingsValue.schedulesTimeZone = null;
          } else if (k.key === 'directories') {
            selectedSettingsValue.directories = null;
            selectedSettingsValue.userGroups = null;
            selectedSettingsValue.defaultLoginDomain = null;
          } else if (k.key === 'allowLocalLogin') {
            selectedSettingsValue.allowLocalLogin = null;
            selectedSettingsValue.emergencyLocalLoginEnabled = null;
            selectedSettingsValue.emergencyLocalLoginType = null;
          } else if (k.key === 'twoFactorAuthenticationEnabled') {
            selectedSettingsValue.twoFactorAuthenticationEnabled = null;
            selectedSettingsValue.strictTwoFactorAuthentication = null;
            selectedSettingsValue.loginCertificates = null;
          } else {
            selectedSettingsValue[k.key] = null;
          }
        }
      }
    });

    processedData[id] = {
      label,
      settingsCheck,
      settingsNotSelected,
      settingsValue: selectedSettingsValue,
      notSelectedCount: keys.length - settingsSelected.length,
      selectedCount: settingsSelected.length,
      totalCount: keys.length,
    };
    return true;
  });
  return processedData;
};

export const getAccordianActiveIndex = (settings, data) => {
  let activeIndex = 0;
  let foundIndex = false;
  Object.entries(settings).forEach(([key], index) => {
    if (data[key].selectedCount > 0 && !foundIndex) {
      activeIndex = index;
      foundIndex = true;
    }
  });
  return activeIndex;
};

export const getSourceApplianceSettingsCopiedDate = (
  appliances,
  applianceName,
) => {
  let settingsCopiedAt = '';
  let last6CharsOfId = '';
  let appliance = applianceName;
  if (applianceName.includes(' (')) {
    // if names are duplicate, name will be displayed in below format:
    // hostname.com (ID ending with last-6-chars-of-Id)
    appliance = applianceName.substring(0, applianceName.indexOf(' ('));
    last6CharsOfId = applianceName.slice(
      applianceName.lastIndexOf(' ') + 1,
      applianceName.indexOf(')'),
    );
  }
  appliances.some(item => {
    if (
      item.name === appliance &&
      (last6CharsOfId === '' ||
        item.id.substring(item.id.length - 6) === last6CharsOfId)
    ) {
      settingsCopiedAt = formatScheduleStartTimestamp(
        item?.oneviewSettings_?.updatedAt,
      );
      return true;
    }
    return false;
  });
  return settingsCopiedAt !== NOT_SET ? settingsCopiedAt : '';
};

export const getSourceApplianceId = (appliances, applianceName) => {
  let applianceId = '';
  let last6CharsOfId = '';
  let appliance = applianceName;
  if (applianceName.includes(' (')) {
    // if names are duplicate, name will be displayed in below format:
    // hostname.com (ID ending with last-6-chars-of-Id)
    appliance = applianceName.substring(0, applianceName.indexOf(' ('));
    last6CharsOfId = applianceName.slice(
      applianceName.lastIndexOf(' ') + 1,
      applianceName.indexOf(')'),
    );
  }
  appliances.some(item => {
    if (
      item.name === appliance &&
      (last6CharsOfId === '' ||
        item.id.substring(item.id.length - 6) === last6CharsOfId)
    ) {
      applianceId = item.id;
      return true;
    }
    return false;
  });
  return applianceId;
};

export const getSourceApplianceName = (applianceId, appliances) => {
  let applianceName = null;
  appliances.some(appliance => {
    if (appliance.id === applianceId) {
      applianceName = appliance.name;
      return true;
    }
    return false;
  });
  return applianceName;
};

export const getSourceApplianceVersion = (applianceId, appliances) => {
  let applianceVersion = null;
  appliances.some(appliance => {
    if (appliance.id === applianceId) {
      applianceVersion = appliance.version;
      return true;
    }
    return false;
  });
  return applianceVersion;
};

export const processOvApplianceSettingsResponse = (
  settingData,
  applianceSettings,
) => {
  const processedData = {};
  const singleSettingsInType = ['updates', 'snmp', 'proxy'];
  Object.entries(applianceSettings).map(([id, setting]) => {
    const { label, keys } = setting;
    // calculate settings selected from settings response
    let settingsSelected = [];
    let settingsValue = {};
    if (singleSettingsInType.includes(id)) {
      if (settingData?.[id]) {
        settingsSelected = [id];
        settingsValue = settingData?.[id];
      }
    } else {
      keys.forEach(k => {
        if (settingData?.[id]) {
          if (Object.keys(settingData?.[id]).includes(k.key)) {
            settingsSelected.push(k.key);
            settingsValue[k.key] = settingData?.[id][k.key];
            if (k.key === 'alertEmailFilters') {
              settingsValue.alertEmailDisabled =
                settingData?.[id]?.alertEmailDisabled;
            }
            if (k.key === 'directories') {
              settingsValue.userGroups = settingData?.[id]?.userGroups;
            }
            if (k.key === 'directories') {
              settingsValue.certificates = settingData?.[id]?.certificates;
            }
            if (k.key === 'proxy') {
              settingsValue.certificates = settingData?.[id]?.certificates;
            }
          } else if (k.key === 'emailDestination') {
            if (
              settingData?.[id]?.senderEmailAddress ||
              settingData?.[id]?.smtpServer ||
              settingData?.[id]?.smtpPort ||
              settingData?.[id]?.smtpProtocol
            ) {
              settingsSelected.push(k.key);
              settingsValue.senderEmailAddress =
                settingData?.[id]?.senderEmailAddress;
              settingsValue.smtpServer = settingData?.[id]?.smtpServer;
              settingsValue.smtpPort = settingData?.[id]?.smtpPort;
              settingsValue.smtpProtocol = settingData?.[id]?.smtpProtocol;
            }
          } else if (k.key === 'generalSettingsContactsPartners') {
            if (
              settingData?.[id]?.configuration ||
              settingData?.[id]?.channelPartner ||
              settingData?.[id]?.contacts ||
              settingData?.[id]?.sites
            ) {
              settingsSelected.push(k.key);
              settingsValue.configuration = settingData?.[id]?.configuration;
              settingsValue.channelPartner = settingData?.[id]?.channelPartner;
              settingsValue.contacts = settingData?.[id]?.contacts;
              settingsValue.sites = settingData?.[id]?.sites;
            }
          } else if (k.key === 'defaultCollectionSchedule') {
            if (settingData?.[id]?.schedule) {
              settingsSelected.push(k.key);
              settingsValue.schedule = settingData?.[id]?.schedule;
              settingsValue.schedulesTimeZone =
                settingData?.[id]?.schedulesTimeZone;
            }
          } else if (k.key === 'time') {
            if (
              settingData?.[id]?.ntpServers ||
              settingData?.[id]?.timeSource
            ) {
              settingsSelected.push(k.key);
              settingsValue.ntpServers = settingData?.[id]?.ntpServers;
              settingsValue.timeSource = settingData?.[id]?.timeSource;
            }
          } else if (k.key === 'languageLocale') {
            if (
              settingData?.[id]?.locale ||
              settingData?.[id]?.localeDisplayName
            ) {
              settingsSelected.push(k.key);
              settingsValue.locale = settingData?.[id]?.locale;
              settingsValue.localeDisplayName =
                settingData?.[id]?.localeDisplayName;
            }
          }
        }
      });
    }

    // get not selected settings to show in accordion
    const settingsNotSelected = [];
    const settingsCheck = {};
    keys.forEach(k => {
      if (settingsSelected.includes(k.key)) {
        settingsCheck[k.key] = true;
        if (k.key === 'certValidationConfig') {
          settingsCheck.autoDownloadCRL = true;
          settingsCheck.checkCertificateRevocation = true;
          settingsCheck.validateCertificate = true;
        }
        if (k.key === 'alertEmailFilters') {
          settingsCheck.alertEmailDisabled = true;
        }
      } else {
        settingsCheck[k.key] = false;
        settingsNotSelected.push(k);
      }
    });

    // prepared data for accordion view
    processedData[id] = {
      label,
      settingsCheck,
      settingsNotSelected,
      settingsValue,
      notSelectedCount: keys.length - settingsSelected.length,
      selectedCount: settingsSelected.length,
      totalCount: keys.length,
    };
    return true;
  });
  return processedData;
};

export const getCategoryMapped = (category, customBiosSettingsFlag) => {
  if (category === SETTING_CATEGORY_FIRMWARE) {
    return SETTING_CATEGORY_SERVER_FIRMWARE;
  }
  if (category === SETTING_CATEGORY_OS_IMAGE) {
    return SETTING_CATEGORY_SERVER_OS_IMAGE;
  }
  if (category === SETTING_CATEGORY_INTERNAL_STORAGE) {
    return SETTING_CATEGORY_SERVER_INTERNAL_STORAGE;
  }
  if (category === SETTING_CATEGORY_DISPLAY_NAME_EXTERNAL_STORAGE) {
    return SETTING_CATEGORY_DISPLAY_NAME_SERVER_EXTERNAL_STORAGE;
  }
  if (category === SETTING_CATEGORY_DISPLAY_NAME_BIOS) {
    return customBiosSettingsFlag
      ? SETTING_CATEGORY_SERVER_BIOS
      : SETTING_CATEGORY_DISPLAY_NAME_BIOS;
  }
  return category;
};

export const removeServerPrefix = settingCategoryMap => {
  const updatedSettingCategoryMap = {};
  Object.entries(settingCategoryMap).forEach(([key, value]) => {
    if (key === 'FIRMWARE') {
      updatedSettingCategoryMap.FIRMWARE = SETTING_CATEGORY_FIRMWARE;
    } else if (key === 'INTERNAL_STORAGE') {
      updatedSettingCategoryMap.INTERNAL_STORAGE =
        SETTING_CATEGORY_INTERNAL_STORAGE;
    } else if (key === 'STORAGE') {
      updatedSettingCategoryMap.STORAGE = SETTING_CATEGORY_INTERNAL_STORAGE;
    } else if (key === 'OS_IMAGE') {
      updatedSettingCategoryMap.OS_IMAGE = SETTING_CATEGORY_OS_IMAGE;
    } else if (key === 'OS') {
      updatedSettingCategoryMap.OS = SETTING_CATEGORY_OS_IMAGE;
    } else if (key === 'EXTERNAL_STORAGE') {
      updatedSettingCategoryMap.EXTERNAL_STORAGE =
        SETTING_CATEGORY_DISPLAY_NAME_EXTERNAL_STORAGE;
    } else {
      updatedSettingCategoryMap[key] = value;
    }
  });
  return updatedSettingCategoryMap;
};

export function validateIloSettingConfiguration(formValues) {
  const result = { isValid: true };
  if (
    !formValues.iloSecurityConfigurationGroups?.length &&
    !formValues.iloSnmpConfigurationGroups?.length
  ) {
    result.isValid = false;
    result.errorMessage = i18n.t(
      'common:server_setting_wizard.step_set_ilo_config.config_validation',
    );
    result.validator = validateIloSettingConfiguration;
  }
  if (
    formValues.iloSnmpConfigurationGroups?.length &&
    !formValues.iloSecurityConfigurationGroups?.includes(ILO_NETWORK)
  ) {
    result.isValid = false;
    result.errorMessage = i18n.t(
      'common:server_setting_wizard.step_set_ilo_config.config_validation_snmp',
    );
    result.validator = validateIloSettingConfiguration;
  }
  return result;
}

export function applianceVersionCheck(applianceVersion, minAppVersion) {
  return compareBundles(applianceVersion, minAppVersion) <= 0;
}

export function getSourceApplianceState(applianceId, appliances) {
  let applianceState = '';
  appliances.some(appliance => {
    if (appliance.id === applianceId) {
      applianceState = appliance.applianceState_;
      return true;
    }
    return false;
  });
  return applianceState;
}

export function convertVolumeSizeGBToGiB(sizeInGB) {
  const VOLUME_SIZE_IN_BYTES = convertSizeFromGBToBytes(sizeInGB);
  return convertSizeFromBytestoGiBWithTwoDecimal(VOLUME_SIZE_IN_BYTES);
}

export function getVolumeEntries(volumes) {
  const volEntries = [];
  volumes.forEach(v => {
    let isDrivesReadOnly = false;
    if (MIN_DRIVE_FOR_RAID_TYPES[v.raidType].step === 0) {
      isDrivesReadOnly = true;
    }
    volEntries.push({
      id: v.id ? v.id : uuidv4(),
      raidType: v.raidType,
      capacityInGiB: v.capacityInGiB ? Number(v.capacityInGiB) : null,
      entireDiskOrSpecificVolume:
        v.capacityInGiB === -1 || v.capacityInGiB === null
          ? USE_ENTIRE_DISK
          : SPECIFY_MULTI_VOLUME_SIZE,
      numOfDrivesReadOnly: isDrivesReadOnly,
      driveCount: v.driveCount
        ? v.driveCount
        : Number(MIN_DRIVE_FOR_RAID_TYPES[v.raidType].min),
      spareDriveCount: v.spareDriveCount === null ? 0 : v.spareDriveCount,
      driveTechnology: v.driveTechnology ? v.driveTechnology : 'NOT_SPECIFIED',
      ioPerformanceMode:
        v.ioPerfModeEnabled === null
          ? 'NOT_MANAGED'
          : (v.ioPerfModeEnabled && 'ENABLED') ||
            (!v.ioPerfModeEnabled && 'DISABLED'),
      readCachePolicy: v.readCachePolicy ? v.readCachePolicy : 'NOT_MANAGED',
      writeCachePolicy: v.writeCachePolicy ? v.writeCachePolicy : 'NOT_MANAGED',
    });
  });

  return volEntries;
}

export function checkIpPoolExist(serverSettings) {
  return (
    serverSettings?.settings?.DEFAULT?.foundAddressPoolUsage &&
    serverSettings?.settings?.DEFAULT?.foundAddressPoolUsage?.length > 0
  );
}
